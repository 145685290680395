import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import fromEntries from 'fromentries'
import CircularProgress from '@material-ui/core/CircularProgress'
import events, { EVENT_SHOW_LOGIN_MODAL } from '@src/utils/events'
import { azureApiGatewayGet } from '@src/api/azureApiGateway/client'

const Redirect = ({ location }) => {
	useEffect(() => {
		const urlParams = new URLSearchParams(location.search)
		const params = fromEntries(urlParams)
		const { target = '', ...rest } = params
		if (target === 'app-registration') {
			navigate('bli-medlem', { state: rest })
		} else if (params.showLoginModal) {
			events.emit(EVENT_SHOW_LOGIN_MODAL, {
				callback: () => {
					navigate('/')
				},
				closeCallback: () => {
					navigate('/')
				},
			})
		} else if (target === 'incomplete-registration') {
			events.emit(EVENT_SHOW_LOGIN_MODAL, {
				showRedirectInfoText: true,
				callback: () => {
					navigate('/')
				},
				closeCallback: () => {
					navigate('/')
				},
			})
		} else if (target === 'station') {
			goToStation(rest.station)
		} else {
			navigate(target, { state: rest })
		}
	}, [location.search])

	return (
		<div
			style={{
				display: 'flex',
				flex: 1,
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<CircularProgress />
		</div>
	)
}

export default Redirect

async function goToStation(station) {
	try {
		const response = await azureApiGatewayGet('station')

		const stations =
			response.data && response.data.stations ? response.data.stations : []

		const selectedStation = stations.find((s) => {
			return s.codename === station
		})
		if (!selectedStation) {
			throw new Error('Station not found')
		}
		navigate('/boka-bil', {
			state: {
				selectedStation,
			},
		})
	} catch (error) {
		navigate('404')
	}
}
